import { Link } from "react-router-dom";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import React, { useContext, useEffect, useState } from "react";

import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../common/ModalSave";
import { Context } from "../../../../utils/context";
import { Controller, useFieldArray } from "react-hook-form";
import AddedLottieModal from "../../../Hrms-Dashboard/commonLottie/AddedLottieModal";
import { useNavigate, useParams } from "react-router";


import { format, isBefore, isAfter } from "date-fns";

const License = () => {

  const { id } = useParams();

  const navigate = useNavigate();
  const [showLottie, setshowLottie] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { postData, Select2Data, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      thired_party_api: [],
    },
  });

  //
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "thired_party_api",
    }
  );
  //

  console.log(getValues(), "getValues");

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("license_s_date", data?.license_s_date);
      sendData.append("license_e_date", data?.license_e_date);
      sendData.append("password", data?.password);
      sendData.append("sub_domain", data?.sub_domain);
      sendData.append("database_name", data?.database_name);
      sendData.append("database_user_name", data?.database_user_name);
      sendData.append("database_password", data?.database_password);

      sendData.append(
        "thired_party_api",
        JSON.stringify(data?.thired_party_api)
      );

      if (data?.logo && data.logo.length > 0) {
        sendData.append("logo", data.logo[0]);
      }

      sendData.append("complaint", data?.complaint);
      sendData.append("hrms", data?.hrms);
      sendData.append("project", data?.project);
      sendData.append("lead", data?.lead);

      const response = await postData(
        `/super-admin/subscriptions/license/${id}`,
        sendData
      );

      if (response?.success) {
        setshowLottie(true);
        setTimeout(() => {
          setshowLottie(false);
          reset();
          navigate("/super-admin/license");
          // props.onHide();
        }, 2000);
      } else {
        setshowLottie();
      }
    } catch (error) {
      console.log(error);
    }
  };


  // ----------------------------------------------------------------
  useEffect(() => {
    // Validate end date whenever start date changes
    if (startDate && endDate) {
      if (isBefore(endDate, startDate)) {
        setValue("license_e_date", ""); // Clear end date if it is before start date
        setEndDate(null); // Reset endDate state
      }
    }
  }, [startDate, endDate, setValue]);

  console.log("getValues", getValues);


  const GetEditData = async () => {
    const response = await getData(
      `/super-admin/subscriptions/license/${id}`
    );

    if (response?.success) {
      const startDateVal = response?.data?.license_s_date;
      const endDateVal = response?.data?.license_e_date;

      // Check if the values are valid date strings before converting them
      if (startDateVal && !isNaN(new Date(startDateVal).getTime())) {
        setStartDate(new Date(startDateVal));
      }
      if (endDateVal && !isNaN(new Date(endDateVal).getTime())) {
        setEndDate(new Date(endDateVal));
      }

      reset(response?.data);
    }
  };

  const GetAllCountry = async () => {

    {
      const response = await getData("/masters/all-thired-party-api-charges");
      if (response?.success) {
        // setThired_party_api(response?.data);

        const formattedData = response?.data?.map((val) => ({
          name: val.name,
          thired_party_api_id: val.id,
          status: false,
          coins: "",
        }));
        // console.log(formattedData);
        setValue("thired_party_api", formattedData);
      }
    }
  };

  useEffect(() => {
    GetEditData();
  }, [id]);

  useEffect(() => {
    GetAllCountry();
  }, []);

  return (
    <>
      <section className="maintablesec">
        <div className="addsection">
          <h6 className="addtitle">Subcribers License</h6>
        </div>

        <div className="addformseccc mt-5">
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <div className="row">



              {/* <div className="col-lg-4 mb-4">
                <Form.Label>Licence No</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="license_no"
                      placeholder="No Of Licence"
                      className={classNames("", {
                        "is-invalid": errors?.license_no,
                      })}
                      {...register("license_no", {
                        required: "Licence No is required",
                      })}
                    />
                  </InputGroup>
                  {errors.license_no && (
                    <span className="text-danger">
                      {errors.license_no.message}
                    </span>
                  )}
                </Form.Group>
              </div> */}

              <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label>
                    License start date<span className="mandatory-star">*</span>
                  </Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      if (date) {
                        setStartDate(date);
                        const formattedDate = format(date, "yyyy-MM-dd");  // Format date to dd/MM/yyyy
                        setValue("license_s_date", formattedDate);
                      }
                    }}
                    className={classNames("form-control", {
                      "is-invalid": errors?.license_s_date,
                    })}
                    name="license_s_date"
                    id="deadline"
                    autoComplete="off"
                    placeholderText="MM/DD/YYYY"
                  />
                  <input
                    type="hidden"
                    {...register("license_s_date", {
                      required: "License start date is required",
                    })}
                  />
                </Form.Group>
                {errors.license_s_date && (
                  <span className="text-danger">
                    {errors.license_s_date.message}
                  </span>
                )}
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label>
                    License End date<span className="mandatory-star">*</span>
                  </Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      if (date) {
                        setEndDate(date);
                        const formattedDate = format(date, "yyyy-MM-dd"); // Format date to MM/dd/yyyy
                        setValue("license_e_date", formattedDate);
                      }
                    }}
                    className={classNames("form-control", {
                      "is-invalid": errors?.license_e_date,
                    })}
                    name="license_e_date"
                    id="license_e_date"
                    autoComplete="off"
                    placeholderText="MM/DD/YYYY"
                    minDate={new Date()} // Prevent past dates
                  />
                  <input
                    type="hidden"
                    {...register("license_e_date", {
                      required: "License End date is required",
                      validate: (value) => {
                        const startDateValue = getValues("license_s_date");
                        const startDateObj = new Date(startDateValue);
                        return (
                          !value ||
                          isAfter(new Date(value), startDateObj) ||
                          "License End date must be after the Start date"
                        );
                      },
                    })}
                  />
                </Form.Group>
                {errors.license_e_date && (
                  <span className="text-danger">
                    {errors.license_e_date.message}
                  </span>
                )}
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Sub Domain Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="sub_domain"
                      placeholder="Sub Domain Name"
                      className={classNames("", {
                        "is-invalid": errors?.sub_domain,
                      })}
                      {...register("sub_domain", {
                        required: "Sub Domain Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.sub_domain && (
                    <span className="text-danger">
                      {errors.sub_domain.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Database Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="database_name"
                      placeholder="Database Name"
                      className={classNames("", {
                        "is-invalid": errors?.database_name,
                      })}
                      {...register("database_name", {
                        required: "Database Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.database_name && (
                    <span className="text-danger">
                      {errors.database_name.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Database user Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="database_user_name"
                      placeholder="Name"
                      className={classNames("", {
                        "is-invalid": errors?.database_user_name,
                      })}
                      {...register("database_user_name", {
                        required: "Database User Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.database_user_name && (
                    <span className="text-danger">
                      {errors.database_user_name.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Database Password</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="database_password"
                      placeholder="Database Password"
                      className={classNames("", {
                        "is-invalid": errors?.database_password,
                      })}
                      {...register("database_password", {
                        required: "Database Password is required",
                      })}
                    />
                  </InputGroup>
                  {errors.database_password && (
                    <span className="text-danger">
                      {errors.database_password.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              {/* <div className="col-lg-4 mb-4"></div>
              <div className="col-lg-4 mb-4"></div> */}


              <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label className="lablestirped">
                    Type Management<span className="mandatory-star">*</span>
                  </Form.Label>
                  <Form>
                    <Form.Check
                      inline
                      label="Complaint Management"
                      name="group1"
                      type="checkbox"
                      {...register(`complaint`)}
                      id={`inline-checkbox-1`}
                    />
                    <Form.Check
                      inline
                      label="HRMS Management"
                      name="group1"
                      type="checkbox"
                      {...register(`hrms`)}
                      id={`inline-checkbox-2`}
                    />
                    <Form.Check
                      inline
                      label="Project Management"
                      name="group1"
                      {...register(`project`)}
                      type="checkbox"
                      id={`inline-checkbox-3`}
                    />{" "}
                    <Form.Check
                      inline
                      label="Lead Management"
                      name="group1"
                      {...register(`lead`)}
                      type="checkbox"
                      id={`inline-checkbox-3`}
                    />
                  </Form>
                </Form.Group>
              </div>

              <div className="col-lg-12 mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <Form.Group className="form-group">
                      <Form.Label className="lablestirped">
                        Third Party API<span className="mandatory-star">*</span>
                      </Form.Label>
                      <Form>
                        {/* {thired_party_api?.map((val) => (
                          <div
                            key={`inline-${val?.id}`}
                            className="mb-3 d-flex"
                          >
                            <Form.Check
                              inline
                              label={val?.name}
                              name="group1"
                              type={"checkbox"}
                              {...register("thired_party_api", {
                                required: "thired_party_api is required",
                              })}
                              id={`inline-${val?.id}-1`}
                            />
                            <Form.Control
                              {...register("thired_party_api_coins", {
                                required: "thired_party_api_coins is required",
                              })}
                              type="text"
                              placeholder="Enter Coins"
                            />
                          </div>
                        ))} */}

                        {fields?.map((field, index) => (
                          <div
                            key={`inline-${field?.id}`}
                            className="mb-3 d-flex"
                          >
                            <Form.Check
                              inline
                              label={field?.name}
                              type={"checkbox"}
                              {...register(
                                `thired_party_api.${index}.status`,
                                {}
                              )} // Status checkbox for each API
                              id={`inline-${field?.id}-1`}
                            />
                            <Form.Control
                              className={classNames("", {
                                "is-invalid":
                                  errors?.thired_party_api?.[index]?.coins, // Check for coins validation error
                              })}
                              {...register(`thired_party_api.${index}.coins`, {
                                required: watch(
                                  `thired_party_api.${index}.status`
                                )
                                  ? {
                                    value: true,
                                    message: "Coins are required",
                                  }
                                  : false, // No validation if the checkbox is unchecked
                              })}
                              type="number"
                              placeholder="Enter Coins"
                            />
                            {errors?.thired_party_api?.[index]?.coins && (
                              <div className="invalid-feedback">
                                {errors.thired_party_api[index].coins.message}
                              </div>
                            )}
                          </div>
                        ))}
                      </Form>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mb-4 text-center">
                <Form.Group className="form-group">
                  {" "}
                  <button type="submit" className="submit-btn btn me-2">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="submit-btn btn"
                    onClick={() => navigate(`/super-admin/license`)}
                  >
                    Close
                  </button>
                </Form.Group>
              </div>
            </div>
          </Form>
        </div>
      </section>
      <AddedLottieModal
        pageTitle="Subscription Added"
        show={showLottie}
        onHide={() => setshowLottie(false)}
      />
    </>
  );
};

export default License;
