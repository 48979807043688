import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../sidebar_components/Dashboard/Dashboard";
import Subscriber_Add from "../sidebar_components/subscriber/Add";
import Subscriber_Edit from "../sidebar_components/subscriber/Edit";
import Subscriber_Table from "../sidebar_components/subscriber/Table";

import Active_subscriber from "../sidebar_components/subscriber/subscriber-inner/Active_subscriber";
import Expired_subscriber from "../sidebar_components/subscriber/subscriber-inner/Expired_subscriber";
import PageNotFound from "../../PageNotFound/PageNotFound";

// Mastres
import Third_party_api_Add from "../sidebar_components/masters/third_party_api/Add";
import Third_party_api_Edit from "../sidebar_components/masters/third_party_api/Edit";
import Third_party_api_Table from "../sidebar_components/masters/third_party_api/Table";

import License from "../sidebar_components/license/license";
import License_Table from "../sidebar_components/license/Table";

const Super_admin_routes = () => {
  return (
    <>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Masters  */}
        <Route
          path="/masters/thired-party-api"
          element={<Third_party_api_Table />}
        />
        <Route
          path="/masters/thired-party-api-add"
          element={<Third_party_api_Add />}
        />
        <Route
          path="/masters/thired-party-api-edit/:id"
          element={<Third_party_api_Edit />}
        />

        {/* Subscriptios  */}
        <Route path="/subscriber-add" element={<Subscriber_Add />} />
        <Route path="/license" element={<License_Table />} />
        <Route path="/license/:id" element={<License />} />
        <Route path="/subscriber-edit/:id" element={<Subscriber_Edit />} />
        <Route path="/subscriber" element={<Subscriber_Table />} />
        <Route path="/active-subscriber" element={<Active_subscriber />} />
        <Route path="/expired-subscriber" element={<Expired_subscriber />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default Super_admin_routes;
