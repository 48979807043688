import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddModal from "../Add";
import EditModal from "../Edit";

import Dropdown from "react-bootstrap/Dropdown";

import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { Context } from "../../../../../utils/context";

import { Link, useNavigate, useParams } from "react-router-dom";

import Select from "react-select";
import Pagination from "react-bootstrap/Pagination";
import DeleteModal from "../DeleteModal";
import CustomPagination from "../../../../common/CustomPagination";
import { Check } from "react-bootstrap-icons";

const Active_subscriber = () => {

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
  } = useContext(Context);

  const navigate = useNavigate();

  const [addmodalShow, setAddModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(0);

  const handleClose1 = () => setEditModalShow(0);
  const handleShow1 = (id) => {
    setEditModalShow(id);
  };
  const [deletemodalShow, setDeleteModalShow] = useState(false);


  const getDataAll = async () => {
    const response = await getData(
      `/super-admin/subscriptions/license?page=${currentPage}&per_page=${perPage}&term=${search}&find=${"Active"}`
    );
    await setData(response.data);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  const [data, setData] = useState({});

  console.log(data);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    editmodalShow,
    addmodalShow,
    currentPage,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(
      `/super-admin/subscriptions/subscriptions/${id}`
    );
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  //   const handleClose1 = () => setShowEdit(0);
  //   const handleShow1 = (id) => {
  //     setShowEdit(id);
  //   };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/super-admin/subscriptions/subscriptions/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <section className="maintablesec">
        <div></div>
        {/* <div className="addsection">
          <Link to="/super-admin/subscriber-add">
            <button className="addbtnn btn">Add Subscriber</button>
          </Link>
        </div> */}

        <div className="searchdatadiv">
          <Form>
            <div className="row">
              <div className="col-lg-3 mb-1">
                <Form.Group className="form-group">
                  <Form.Control
                    type="search"
                    placeholder="Search name"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}

                  />
                </Form.Group>
              </div>



              <div className="col-lg-3 mb-1">
                <Form.Group className="form-group">
                  <button
                    className="searchbtnn btn"
                    type="button"
                    onClick={getDataAll}
                  >
                    Search
                  </button>
                </Form.Group>
              </div>
              <div className="col-lg-3 mb-1">
                <Form.Group className="form-group">
                  <button
                    className="searchbtnn btn"
                    type="button"
                    onClick={() => {
                      setSearch("");
                      setReset(!reset);
                    }}
                  >
                    Reset
                  </button>
                </Form.Group>
              </div>
            </div>
          </Form>
        </div>

        {/* <div className="grey-bggg">
          <Row>
            <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={12}>
              <div class="show-entries mt-2">
                <label for="entries">Show</label>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setperPage(e.target.value)}
                >
                  {option?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <label for="entries" className="ms-2">
                  Entries
                </label>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="sowing me-2">
                <p className="text-end showing-txttt mt-2">{`Showing ${Math.min(
                  (currentPage - 1) * perPage + 1
                )} to ${Math.min(
                  currentPage * perPage,
                  data?.data?.totalEntries
                )} of ${data?.data?.totalEntries} Entries`}</p>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12}>
              <div className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search Here"
                    aria-label="Search Here"
                    aria-describedby="basic-addon1"
                    id=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
            <Col xxl={3} xl={6} lg={6} md={12} sm={6} xs={12}>
              <button
                type="button"
                onClick={getDataAll}
                className="btn btn-search mb-3 me-2"
              >
                Search
              </button>
              <button
                type="button"
                onClick={() => {
                  setSearch("");
                  setReset(!reset);
                }}
                className="btn reset-btnn mb-3"
              >
                Reset
              </button>
            </Col>
          </Row>
        </div> */}

        <div className="table-section mt-5">
          {/* <div className="excelbtndiv text-end">
            <button className="excelbtn">Excel Export</button>
          </div> */}
          <div className="table-responsive">
            <table className="table table-bordered table-striped text-center">
              <thead>
                <tr>
                  {visible.col1 && <th className="sr">Sr. No.</th>}
                  {visible.col2 && <th className="tax-name">Name</th>}
                  {visible.col3 && <th className="tax-name">license No</th>}
                  {visible.col4 && <th className="tax-name">Start Date</th>}
                  {visible.col5 && <th className="tax-name">End Date</th>}

                  {visible.col7 && <th className="tax-name">Status</th>}
                  {visible.col8 && <th className="active">Action</th>}
                </tr>
              </thead>
              <tbody className="table-body-holder">
                {data?.data?.map((d, index) => (
                  <tr key={d?.id}>
                    {visible.col1 && <td>{index + 1}.</td>}
                    {visible.col2 && <td>{d?.name}</td>}
                    {visible.col3 && <td>{d?.license_no}</td>}{" "}
                    {visible.col4 && <td>{d?.subscriptions_license?.license_s_date}</td>}{" "}
                    {visible.col5 && <td>{d?.subscriptions_license?.license_e_date}</td>}{" "}
                    {visible.col7 && (
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={d.status} // Use 'd' instead of 'data'
                            onChange={() => {
                              ChangeStatus(d.id); // Use 'd' instead of 'data'
                            }}
                            id={`flexSwitchCheckDefault${d?.id}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexSwitchCheckDefault${d?.id}`}
                          >
                            {d.status ? "Active" : "Inactive"}{" "}
                            {/* Use 'd' instead of 'data' */}
                          </label>
                        </div>
                      </td>
                    )}
                    {visible.col8 && (
                      <td>
                        <div className="d-flex">
                          {/* <button
                            className="btn editbtn"
                            type="button"
                            onClick={() =>
                              navigate(`/super-admin/subscriber-edit/${d?.id}`)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              type="button"
                            />
                          </button> */}

                          <button
                            className="btn editbtn"
                            type="button"
                            onClick={() => navigate(`/super-admin/license/${d?.id}`)}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              type="button"
                            />
                          </button>

                          {/* <button
                            className="btn deletbtn"
                            type="button"
                            onClick={() => showDeleteRecord(d?.id, d?.name)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button> */}

                          {/* <FontAwesomeIcon
                            icon={faTrash}
                            className="table-iconnnn ms-2"
                            onClick={() => showDeleteRecord(d?.id, d?.name)}
                          /> */}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="paginationdivv text-center mt-3">
            <CustomPagination
              currentPage={currentPage}
              totalPages={data?.totalPages}
              handlePageChange={handlePageChange}
            />
            {/* <Pagination>
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{9}</Pagination.Item>
              <Pagination.Item>{10}</Pagination.Item>
              <Pagination.Next />
            </Pagination> */}
          </div>
        </div>

        <DeleteModal
          show={showDeleteModal}
          handleDeleteRecord={handleDeleteRecord}
          handleDeleteCancel={handleDeleteCancel}
        />
      </section>
    </>
  );
};

export default Active_subscriber;
